import React, { useState } from "react";
import { useAlert } from "react-alert";
import api from "../../helper/api";
import { sendEmail } from "../../helper/Email/sendEmail";

function LocationSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const alert = useAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post("/content/item/Feedback", {
        data: { Name: name, Email: email, Message: message },
      });
      const emailBody = `<!DOCTYPE html>
      <html>
      <head>
          <title></title>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <style type="text/css">
              body,
              table,
              td,
              a {
                  -webkit-text-size-adjust: 100%;
                  -ms-text-size-adjust: 100%;
              }
      
              table,
              td {
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
              }
      
              img {
                  -ms-interpolation-mode: bicubic;
              }
      
              img {
                  border: 0;
                  height: auto;
                  line-height: 100%;
                  outline: none;
                  text-decoration: none;
              }
      
              table {
                  border-collapse: collapse !important;
              }
      
              body {
                  height: 100% !important;
                  margin: 0 !important;
                  padding: 0 !important;
                  width: 100% !important;
              }
      
      
              a[x-apple-data-detectors] {
                  color: inherit !important;
                  text-decoration: none !important;
                  font-size: inherit !important;
                  font-family: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
              }
      
              @media screen and (max-width: 480px) {
                  .mobile-hide {
                      display: none !important;
                  }
      
                  .mobile-center {
                      text-align: center !important;
                  }
              }
      
              div[style*="margin: 16px 0;"] {
                  margin: 0 !important;
              }
          </style>
      
      <body style="margin: 0 !important; padding: 0 !important; background-color: #eeeeee;" bgcolor="#eeeeee">
          <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                  <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">
                      <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">
                          <tr>
                              <td align="center" valign="top" style="font-size:0;" bgcolor="#000000">
                                  <img src="https://i.imgur.com/35v9fgd.png" width="160" height="160"
                                      style="display: block; border: 0px; margin: 0 auto;" /><br>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" style="padding: 10px 10px 10px 10px; background-color: #ffffff;"
                                  bgcolor="#ffffff">
                                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                      style="max-width:600px;">
                                      <tr>
                                          <td align="center"
                                              style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 25px;">
                                              <img src="https://cdn-icons-png.flaticon.com/512/4658/4658943.png" width="125"
                                                  height="120" style="display: block; border: 0px;" /><br>
                                              <h2
                                                  style="font-size: 30px; font-weight: 800; line-height: 36px; color: #333333; margin: 0;">
                                                  Feedback Notification
                                              </h2>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td align="left" style="padding-top: 20px;">
                                              <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                                  <tr>
                                                      <td width="75%" align="left" bgcolor="#eeeeee"
                                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                          Feedback Details
                                                      </td>
                                                      <td width="25%" align="left" bgcolor="#eeeeee"
                                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                      </td>
                                                  </tr>
      
                                                  <tr>
                                                      <td colspan="2" align="left"
                                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                          Name: ${name}
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td colspan="2" align="left"
                                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                          Email: ${email}
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td colspan="2" align="left"
                                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                          Message: ${message}
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                              <td align="center" height="100%" valign="top" width="100%"
                                  style="padding: 0 10px 10px 10px; background-color: #ffffff;" bgcolor="#ffffff">
                                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                      style="max-width:660px;">
                                      <tr>
                                          <td align="center" valign="top" style="font-size:0;">
                                              <div
                                                  style="display:inline-block; max-width:50%; min-width:240px; vertical-align:top; width:100%;">
                                              </div>
                                              <div
                                                  style="display:inline-block; max-width:50%; min-width:24px; vertical-align:top; width:80%;">
                                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                                                      style="max-width:300px;">
                                                      <tr>
                                                          <td align="left" valign="top"
                                                              style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </div>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <tr>
                          </tr>
                          <tr>
                              <td align="center" style="padding: 10px 10px 10px 10px; background-color: #fafafa;">
                                  <img src="https://i.imgur.com/35v9fgd.png" width="100" height="100"
                                      style="display: block; border: 0px;" />
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
      </body>
      
      </html>`;
      sendEmail("fawda@fawda.shop", "Feedback Recieved", emailBody);
      alert.show("Feedback submitted successfully", { type: "success" });
    } catch (error) {
      console.log(error);
    }

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5  mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0"
            title="map"
            src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=1%20Zahraa%20Al%20Maadi,%20Maadi%20as%20Sarayat%20Al%20Gharbeyah,%20El%20Basatin,%20Cairo%20Governorate%204234110%20Cairo+(Domanza)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            style={{ filter: "contrast(1) opacity(0.5)" }}
          />
          <div className="bg-white relative flex flex-wrap py-6 w-full rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ADDRESS (Domanza)
              </h2>
              <p className="mt-1">
                1 Zahraa Al Maadi, Maadi as Sarayat Al Gharbeyah, El Basatin,
                Cairo, Egypt
              </p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                EMAIL
              </h2>
              <a
                href="mailto:fawda@fawda.shop"
                className="text-pcolor leading-relaxed"
              >
                fawda@fawda.shop
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                PHONE
              </h2>
              <p className="leading-relaxed">+201014864675</p>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="py-4 lg:w-1/2 md:w-1/2 bg-white flex flex-col px-6 md:ml-auto w-full md:py-8 mt-8 md:mt-0"
        >
          {" "}
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Feedback
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            Your feedback is important to us.
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-600">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-600"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            type="submit"
            className="text-white bg-maincolor border-0 py-2 px-6 focus:outline-none hover:bg-secondarycolor hover:text-maincolor rounded text-lg"
          >
            Submit Feedback
          </button>
        </form>
      </div>
    </section>
  );
}

export default LocationSection;

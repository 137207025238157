import React from "react";
import QuantityChoice from "../../ProductDetails/QuantityChoice";
import { useCart } from "react-use-cart";
import { useAlert } from "react-alert";

export default function CartItem(props) {
  const { item, removeItem } = props;
  const alert = useAlert();
  const { updateItemQuantity } = useCart();
  return (
    <li className="flex items-center gap-2">
      <img src={item.img} alt="" className="h-16 w-16 rounded object-cover" />
      <div>
        <h3 className="text-sm text-gray-900">
          {" "}
          {item.name} x{item.quantity}{" "}
        </h3>
        <dl className="mt-0.5 space-y-px text-[10px] text-gray-600">
          <div>
            <dt className="inline">Size:</dt>
            <dd className="inline"> {item.size} </dd>
          </div>
          <div>
            <dt className="inline">Color:</dt>
            <dd className="inline">{item.color}</dd>
          </div>
        </dl>
      </div>
      <div className="flex flex-1 items-center justify-end">
        <div className="flex items-center ps-4">
          <QuantityChoice
            decreaseQuantity={() => {
              if (item.quantity === 1)
                alert.show(`${item.name} removed from cart !`, {
                  type: "success",
                });
              updateItemQuantity(item.id, item.quantity - 1);
            }}
            selectedQuantity={item.quantity}
            handleQuantityChange={() => {}}
            maxQuantity={item.variant.variantQuantity}
            increaseQuantity={() => {
              item.quantity < item.variant.variantQuantity
                ? updateItemQuantity(item.id, item.quantity + 1)
                : alert.show("Sorry, Insufficient Stock ", { type: "error" });
            }}
          />
        </div>
        <button
          onClick={() => {
            alert.show(`${item.name} removed from cart !`, { type: "success" });
            removeItem(item.id);
          }}
          className="text-gray-600 transition hover:text-melon focus:outline-none focus:ring-2 focus:ring-melon focus:ring-opacity-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-5 w-5 mb-3 lg:h-4 lg:w-4 lg:mb-0"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </button>
      </div>
    </li>
  );
}

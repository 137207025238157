import React from "react";
import WhoAreWe from "../components/AboutUs/WhoAreWe";
import { motion } from "framer-motion";
import ReusableHeroSection from "../components/HeroSections/ReusableHeroSection";
import LocationSection from "../components/AboutUs/LocationSection";

function AboutPage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ReusableHeroSection pageName="About Us" />
      <WhoAreWe />
      <LocationSection />
      {/* spacing */}
      <div className="h-20"></div>
    </motion.div>
  );
}

export default AboutPage;

import React from 'react'
import { Link } from 'react-router-dom'

export default function CartCheckoutButton(props) {
    const { showSidecart } = props;
    const { to } = props;
    return (
        <div className="text-center">
            <Link
            to={to}
            onClick={showSidecart}
            className="block rounded bg-maincolor px-5 py-3 text-sm text-gray-100 transition hover:bg-gray-300 hover:text-maincolor"
            >
            Checkout
            </Link>
        </div>
  )
}

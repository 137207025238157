import api from "../api";

function sendEmailToAdmin(userData, order, total, shipping) {
  const html = `<!DOCTYPE html>
  <html>
  
  <head>
      <title></title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <style type="text/css">
          body,
          table,
          td,
          a {
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
          }
  
          table,
          td {
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
          }
  
          img {
              -ms-interpolation-mode: bicubic;
          }
  
          img {
              border: 0;
              height: auto;
              line-height: 100%;
              outline: none;
              text-decoration: none;
          }
  
          table {
              border-collapse: collapse !important;
          }
  
          body {
              height: 100% !important;
              margin: 0 !important;
              padding: 0 !important;
              width: 100% !important;
          }
  
  
          a[x-apple-data-detectors] {
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
          }
  
          @media screen and (max-width: 480px) {
              .mobile-hide {
                  display: none !important;
              }
  
              .mobile-center {
                  text-align: center !important;
              }
          }
  
          div[style*="margin: 16px 0;"] {
              margin: 0 !important;
          }
      </style>
  
  <body style="margin: 0 !important; padding: 0 !important; background-color: #eeeeee;" bgcolor="#eeeeee">
  
  
  
  
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
              <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">
  
                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">
                      <tr>
                          <td align="center" valign="top" style="font-size:0;" bgcolor="#000000">
                              <img src="https://i.imgur.com/35v9fgd.png" width="160" height="160"
                                  style="display: block; border: 0px; margin: 0 auto;" /><br>
                          </td>
                      </tr>
                      <tr>
                          <td align="center" style="padding: 10px 10px 10px 10px; background-color: #ffffff;"
                              bgcolor="#ffffff">
                              <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                  style="max-width:600px;">
                                  <tr>
                                      <td align="center"
                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 25px;">
                                          <img src="https://static.vecteezy.com/system/resources/previews/010/366/210/original/bell-icon-transparent-notification-free-png.png"
                                              width="125" height="120" style="display: block; border: 0px;" /><br>
                                          <h2
                                              style="font-size: 30px; font-weight: 800; line-height: 36px; color: #333333; margin: 0;">
                                              You Received New Order
                                          </h2>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td align="left" style="padding-top: 20px;">
                                          <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                              <tr>
                                                  <td width="75%" align="left" bgcolor="#eeeeee"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                      Order Details
                                                  </td>
                                                  <td width="25%" align="left" bgcolor="#eeeeee"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
  
                                                  </td>
                                              </tr>
  
                                              ${order
                                                .map((item) => {
                                                  return `<tr>
                                                  <td width="75%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                      ${item.name} (${
                                                    item.size
                                                  }) x${item.quantity}
                                                  </td>
                                                  <td width="25%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                      ${
                                                        item.price *
                                                        item.quantity
                                                      } EGP
                                                  </td>
                                              </tr>`;
                                                })
                                                .join("")}
                                              <tr>
                                                  <td width="75%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                      Shipping
                                                  </td>
                                                  <td width="25%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                      ${shipping} EGP
                                                  </td>
                                              </tr>
  
                                          </table>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td align="left" style="padding-top: 20px;">
                                          <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                              <tr>
                                                  <td width="75%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;">
                                                      TOTAL
                                                  </td>
                                                  <td width="25%" align="left"
                                                      style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;">
                                                      ${total} EGP
                                                  </td>
                                              </tr>
                                          </table>
                                      </td>
                                  </tr>
                              </table>
  
                          </td>
                      </tr>
                      <tr>
                          <td align="center" height="100%" valign="top" width="100%"
                              style="padding: 0 10px 10px 10px; background-color: #ffffff;" bgcolor="#ffffff">
                              <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                  style="max-width:660px;">
                                  <tr>
                                      <td align="center" valign="top" style="font-size:0;">
                                          <div
                                              style="display:inline-block; max-width:50%; min-width:240px; vertical-align:top; width:100%;">
  
                                              <!-- <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:300px;"> -->
                                  <tr>
                                      <td align="left" valign="top"
                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                          <p style="font-weight: 800;">Full Name</p>
                                          <p>${userData.name}</p>
  
                                      </td>
                                      <td align="left" valign="top"
                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                          <p style="font-weight: 800;">Email</p>
                                          <p>${userData.email}</p>
  
                                      </td>
                                  </tr>
                                  <tr>
                                      <td align="left" valign="top"
                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                          <p style="font-weight: 800;">Phone Number</p>
                                          <p>${userData.phone}</p>
  
                                      </td>
                                      <td align="left" valign="top"
                                          style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                          <p style="font-weight: 800;">Delivery Address</p>
                                          <p>${userData.streetName}, ${
    userData.buildingNumber
  }, ${userData.floor}, ${userData.apartmentNumber}</p>
  
                                      </td>
                                  </tr>
                                  <!-- </table> -->
                                  </div>
                                  <div
                                      style="display:inline-block; max-width:50%; min-width:24px; vertical-align:top; width:80%;">
                                      <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                                          style="max-width:300px;">
                                          <tr>
                                              <td align="left" valign="top"
                                                  style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                              </td>
                                          </tr>
                                      </table>
                                  </div>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
          <tr>
  
  
          </tr>
          <tr>
              <td align="center" style="padding: 10px 10px 10px 10px; background-color: #fafafa;">
                  <img src="https://i.imgur.com/35v9fgd.png" width="100" height="100"
                      style="display: block; border: 0px;" />
              </td>
          </tr>
      </table>
      </td>
      </tr>
      </table>
  
  </body>
  
  </html>`;

  api
    .post("/content/item/Email", {
      data: {
        to: "fawda@fawda.shop",
        subject: "New Order Recieved",
        body: html,
      },
    })
    .catch((err) => {
      console.log(err);
    });
}

export default sendEmailToAdmin;

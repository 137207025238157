export default function ProductCard(props) {
  const { name, oldPrice, price, image } = props;

  return (
    <div className="group bg-gray-50 p-3 lg:p-8 transition duration-300 ease-in-out transform hover:bg-melon hover:scale-95 ">
      <img
        src="/reusable-hero.jpeg"
        alt="background"
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <div className="flex justify-center items-center">
        <img className="" src={image} alt={name} />
      </div>
      <div className="mt-4">
        <h2 className="text-sm lg:text-xl text-slate-100 font-extrabold blur-sms transition duration-300 ease-in-out transform group-hover:text-white">
          {name}
        </h2>
        <div className="flex items-center">
          <p className="text-sm lg:text-xl font-semibold text-white transition duration-300 ease-in-out transform group-hover:text-white">
            LE {price}
          </p>
          {oldPrice && (
            <span className="text-sm lg:text-lg line-through text-s transition duration-300 ease-in-out transform group-hover:text-white">
              LE {oldPrice}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

import { sendEmail } from "../Email/sendEmail";
import { getStockForItem } from "../Products/getStockForItem";

export async function checkStockAndNotify(items) {
  let lowStockItems = [];

  for (const item of items) {
    try {
      const currentStock = await getStockForItem(
        item.id,
        item.variant.variantId
      );
      if (currentStock <= 3) {
        lowStockItems.push({
          name: item.name,
          color: item.variant.variantColor,
          size: item.variant.variantSize,
          currentStock: currentStock,
        });
      }
    } catch (error) {
      console.error(
        `Error checking stock for item ${item.id}, variant ${item.variant.variantId}:`,
        error
      );
    }
  }

  if (lowStockItems.length > 0) {
    let emailBody = `
    <!DOCTYPE html>
<html>

<head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <style type="text/css">
        body,
        table,
        td,
        a {
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        table,
        td {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            -ms-interpolation-mode: bicubic;
        }

        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
        }

        table {
            border-collapse: collapse !important;
        }

        body {
            height: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
        }


        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        @media screen and (max-width: 480px) {
            .mobile-hide {
                display: none !important;
            }

            .mobile-center {
                text-align: center !important;
            }
        }

        div[style*="margin: 16px 0;"] {
            margin: 0 !important;
        }
    </style>

<body style="margin: 0 !important; padding: 0 !important; background-color: #eeeeee;" bgcolor="#eeeeee">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">
                    <tr>
                        <td align="center" valign="top" style="font-size:0;" bgcolor="#000000">
                            <img src="https://i.imgur.com/35v9fgd.png" width="160" height="160"
                                style="display: block; border: 0px; margin: 0 auto;" /><br>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="padding: 10px 10px 10px 10px; background-color: #ffffff;"
                            bgcolor="#ffffff">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="max-width:600px;">
                                <tr>
                                    <td align="center"
                                        style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 25px;">
                                        <img src="https://static.wixstatic.com/media/fd6699_5ad46a373a2e4385bddfb0c531358041~mv2.png"
                                            width="125" height="120" style="display: block; border: 0px;" /><br>
                                        <h2
                                            style="font-size: 30px; font-weight: 800; line-height: 36px; color: #333333; margin: 0;">
                                            Low Stock
                                        </h2>
                                    </td>
                                </tr>
                                <tr>
                                <td align="left" style="padding-top: 20px;">
                                    <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td width="75%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                Low Stock Items
                                            </td>
                                            <td width="25%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                            </td>
                                        </tr>

    ${lowStockItems
      .map((item) => {
        return `<tr>
            <td width="75%" align="left"
                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                ${item.name} (Size: ${item.size}) - (Color: ${item.color})
            </td>
            <td width="25%" align="left"
                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                ${item.currentStock}
            </td>
        </tr>`;
      })
      .join("")}
            </table>
            </td>
        </tr>
      </table>
      </td>
      </tr>
      <tr>
      <td align="center" height="100%" valign="top" width="100%"
      style="padding: 0 10px 10px 10px; background-color: #ffffff;" bgcolor="#ffffff">
      <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
        style="max-width:660px;">
        <tr>
            <td align="center" valign="top" style="font-size:0;">
                <div
                    style="display:inline-block; max-width:50%; min-width:240px; vertical-align:top; width:100%;">
                </div>
                <div
                    style="display:inline-block; max-width:50%; min-width:24px; vertical-align:top; width:80%;">
                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style="max-width:300px;">
                        <tr>
                            <td align="left" valign="top"
                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                            </td>
                        </tr>
                    </table>
                </div>
            </td>
        </tr>
      </table>
      </td>
      </tr>
      <tr>
      </tr>
      <tr>
      <td align="center" style="padding: 10px 10px 10px 10px; background-color: #fafafa;">
      <img src="https://i.imgur.com/35v9fgd.png" width="100" height="100"
        style="display: block; border: 0px;" />
      </td>
      </tr>
      </table>
      </td>
      </tr>
      </table>
      </body>

      </html>`;

    sendEmail("fawda@fawda.shop", "Low Stock", emailBody);
  }
}

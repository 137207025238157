import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar/Navbar.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsMinecart } from "react-icons/bs";
import Cart from "./Cart/Cart";
import Sidebar from "./Navbar/Sidebar";
import { useCart } from "react-use-cart";

export default function SiteHeader({ cartItems }) {
  const [sidebar, setSidebar] = useState(false);
  const [sidecart, setSidecart] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const { items } = useCart();

  useEffect(() => {
    if (items.length > 0 && !firstLoad) {
      setSidecart(true);
    }
    setFirstLoad(false);
  }, [items.length]);

  const showSidebar = () => setSidebar(!sidebar);
  const showSidecart = () => setSidecart(!sidecart);

  return (
    <>
      <div
        className={`overlay ${sidecart ? "active" : ""}`}
        onClick={showSidecart}
      />
      <Cart sidecart={sidecart} showSidecart={showSidecart} />
      <div
        className={`overlay ${sidebar ? "active" : ""}`}
        onClick={showSidebar}
      />
      <Sidebar sidebar={sidebar} showSidebar={showSidebar} />

      <nav className="flex z-custom bg-headercolor shadow-custom backdrop-blur-md items-center h-28 justify-between sticky top-0 px-4 py-2 lg:px-6 lg:py-3">
        <Link onClick={showSidebar} className="ml-4 sm:ml-10 text-white">
          <RxHamburgerMenu style={{ width: "25px", height: "25px" }} />
        </Link>
        <Link to={"/"} className="mx-4 sm:mx-10 w-20 lg:w-24">
          <img src="/logo-nobg.png" className="mx-auto scale-200" alt="/" />
        </Link>
        <Link
          onClick={showSidecart}
          className="relative p-2 mr-4 sm:mr-10 text-white"
        >
          <BsMinecart style={{ width: "25px", height: "25px" }} />
          {items.length > 0 && (
            <span
              className={`absolute top-0 right-0 bg-white text-maincolor rounded-full text-xs w-5 h-5 flex items-center justify-center transition-all duration-200 ease-in-out }`}
            >
              {items.length}
            </span>
          )}
        </Link>
      </nav>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import CartItem from "./CartItem";
import CartPricesSection from "./CartPricesSection";
import CartCheckoutButton from "./CartCheckoutButton";
import "./Cart.css";
import { useCart } from "react-use-cart";

export default function Cart({ sidecart, showSidecart }) {
  const { items, removeItem, cartTotal, isEmpty } = useCart();
  return (
    <div className={`cart-menu ${sidecart ? "active" : ""}`}>
      <nav className={sidecart ? "cart-menu active" : "cart-menu"}>
        <ul className="cart-menu-items" onClick={showSidecart}>
          <li className="cart-toggle">
            <Link to="#" className="cart-bars">
              <AiIcons.AiOutlineClose color="black" />
            </Link>
          </li>
        </ul>
        <div
          className="absolute w-full mx-auto bg-transparent px-6 py-7 sm:px-6 lg:px-6 overflow-auto max-h-[calc(100vh-110px)]"
          style={{ top: "110px" }}
        >
          <div className="space-y-2 overflow-auto">
            {!isEmpty ? (
              <>
                <ul className="space-y-6 sm:space-y-4 md:space-y-2 lg:space-y-3 overflow-auto">
                  {items.map((item, index) => {
                    return (
                      <CartItem
                        key={index}
                        item={item}
                        removeItem={removeItem}
                      />
                    );
                  })}
                </ul>
                <br />
                <CartPricesSection subtotal={cartTotal} />
                <CartCheckoutButton
                  to="/checkout"
                  showSidecart={showSidecart}
                />
              </>
            ) : (
              <h2 className="text-sm text-gray-600 cart-empty">
                Your cart is empty
              </h2>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

import React from "react";

export default function QuantityChoice(props) {
    const {
        decreaseQuantity,
        selectedQuantity,
        handleQuantityChange,
        maxQuantity,
        increaseQuantity,
    } = props;
    return (
        <div className='mb-4 mr-4 lg:mb-0 '>
            <div className='w-28'>
                {/* handleQuantityChange */}
                <div className='relative flex flex-row w-full h-10'>
                    <button
                        onClick={decreaseQuantity}
                        className='rounded-l-lg w-20 h-full text-white bg-maincolor border-r border border-white outline-none cursor-pointer hover:text-gray-700 hover:bg-gray-200'
                    >
                        <span className='m-auto text-2xl font-light'>-</span>
                    </button>
                    <input
                        type='number'
                        className='flex items-center w-full font-normal text-center text-gray-700 bg-gray-100 outline-none focus:outline-none text-md hover:text-black'
                        value={selectedQuantity}
                        onChange={(e) => handleQuantityChange(e.target.value)}
                        max={maxQuantity}
                        readOnly
                    />
                    <button
                        onClick={increaseQuantity}
                        className='rounded-r-lg w-20 h-full text-white bg-maincolor border-l border border-white outline-none cursor-pointer hover:text-gray-700 hover:bg-gray-200'
                    >
                        <span className='m-auto text-2xl font-light'>+</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

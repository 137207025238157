import api from "../api";

export function sendEmail(to = "fawda@fawda.shop", subject, body) {
  api
    .post("/content/item/Email", {
      data: {
        to: to,
        subject: subject,
        body: body,
      },
    })
    .catch((error) => {
      console.error("Failed to send email", error);
    });
}

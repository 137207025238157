import React, { useEffect, useState, useMemo, lazy, Suspense } from "react";
import HeroSection from "../components/HeroSections/HeroSection";
import { motion } from "framer-motion";
import { useAlert } from "react-alert";
import { GetAllProducts } from "../helper/Products/GetProducts";

const Collection = lazy(() => import("../components/Product/Collection"));
const BestSeller1 = lazy(() => import("../components/BestSeller1"));

function HomePage() {
  const [products, setProducts] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await GetAllProducts();
        setProducts(data);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          alert.show("Something went wrong. Please try again later", {
            type: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          alert.show("Something went wrong. Please try again later", {
            type: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          alert.show("Something went wrong. Please try again later", {
            type: "error",
          });
        }
      }
    };

    fetchProducts();
  }, []);

  const bestSellers = useMemo(
    () => products.filter((product) => product.isBestSeller),
    [products]
  );
  const latestCollection = useMemo(
    () => products.filter((product) => product.isLatestCollection),
    [products]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <HeroSection />

      <Suspense fallback={<div>Loading...</div>}>
        {latestCollection.length > 0 ? (
          <Collection products={latestCollection} title={"Latest Collection"} />
        ) : null}
        {bestSellers.length > 0 ? <BestSeller1 products={bestSellers} /> : null}
      </Suspense>
    </motion.div>
  );
}

export default HomePage;

import { GetProductById } from "./GetProducts";

export async function getStockForItem(productId, variantId) {
  try {
    const product = await GetProductById(productId);
    const variant = product.productVariants.find(
      (v) => v.variantId === variantId
    );

    return variant ? variant.variantQuantity : 0;
  } catch (error) {
    throw error;
  }
}

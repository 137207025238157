import React, { useState, useEffect } from "react";
import { GetCategories } from "../../../helper/GetCategories";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Sidebar({ sidebar, showSidebar }) {
  const [SidebarData, setSidebarData] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await GetCategories();
        setSidebarData(categories);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };
    fetchCategories();
  }, [setSidebarData]);

  return (
    <div className={`nav-menu ${sidebar ? "active" : ""}`}>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle flex absolute left-0">
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose color="black" />
            </Link>
          </li>
          <li className="nav-text">
            <Link to="/">
              <span>Home</span>
            </Link>
          </li>
          <li className="nav-text">
            <Link to="/Shop">
              <span>Shop All</span>
            </Link>
          </li>
          <li className="nav-text">
            <Link to="/about">
              <span>About</span>
            </Link>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

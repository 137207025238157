import React from "react";
import { Link } from "react-router-dom";

export default function BuyNowButton({ handleBuyNow }) {
    return (
        <Link to={"/checkout"}>
            <button
                onClick={handleBuyNow}
                className='rounded-md w-full h-10 p-2 mr-4 bg-maincolor text-white border border-white hover:bg-gray-200 hover:text-maincolor transition'
            >
                Buy Now
            </button>
        </Link>
    );
}

import React, { useEffect, useMemo } from "react";
import ProductCard from "../components/Product/ProductCard";
import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ReusableHeroSection from "../components/HeroSections/ReusableHeroSection";
import { GetCategoryById } from "../helper/GetCategories";

function CategoryPage() {
  const uri = window.location.pathname.split("/");
  let categoryId = uri.pop();
  let categoryName = uri.pop();
  const [isListView, setIsListView] = useState(false);
  const [sortOrder, setSortOrder] = useState("default");
  const handleViewChange = () => setIsListView(!isListView);
  const handleSortChange = (event) => setSortOrder(event.target.value);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { products } = await GetCategoryById(categoryId);
        if (!products || products.length === 0) {
          setProducts([]);
        } else {
          setProducts(products);
        }
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    };
    fetchProducts();
  }, [categoryId]);

  const sortedProducts = useMemo(() => {
    let sorted = [...products];
    switch (sortOrder) {
      case "price-asc":
        return sorted.sort((a, b) => a.productPrice - b.productPrice);
      case "price-desc":
        return sorted.sort((a, b) => b.productPrice - a.productPrice);
      case "name-asc":
        return sorted.sort((a, b) =>
          a.productName.localeCompare(b.productName)
        );
      case "name-desc":
        return sorted.sort((a, b) =>
          b.productName.localeCompare(a.productName)
        );
      default:
        return sorted;
    }
  }, [sortOrder, products]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ReusableHeroSection
        pageName={`Our ${categoryName.replace(/-/g, " ")}`}
      />{" "}
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-2">
        <header className="flex flex-col items-center justify-center text-center">
          <h2 className="text-xl font-bold text-gray-200 uppercase sm:text-3xl">
            {categoryName.replace(/-/g, " ")}
          </h2>
          <p className="mt-4 max-w-md text-white">
            Explore our wide range of products.
          </p>
        </header>

        <div className="lg:mx-6 mt-8 flex items-center justify-between">
          <div className="flex rounded border border-gray-100">
            <button
              onClick={handleViewChange}
              className="inline-flex h-10 w-10 items-center justify-center border-e text-gray-600 transition hover:bg-gray-50 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="white"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                />
              </svg>
            </button>
            <button
              onClick={handleViewChange}
              className="inline-flex h-10 w-10 items-center justify-center text-gray-600 transition hover:bg-gray-50 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="white"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </button>
          </div>

          <div>
            <label htmlFor="SortBy" className="sr-only">
              SortBy
            </label>
            <select
              value={sortOrder}
              onChange={handleSortChange}
              className="bg-gray-900"
            >
              <option key="default" value="default">
                Default
              </option>
              <option key="price-asc" value="price-asc">
                Price (Low to High)
              </option>
              <option key="price-desc" value="price-desc">
                Price (High to Low)
              </option>
              <option key="name-asc" value="name-asc">
                Name (A to Z)
              </option>
              <option key="name-desc" value="name-desc">
                Name (Z to A)
              </option>
            </select>
          </div>
        </div>

        {products.length > 0 ? (
          <ul className="lg:mt-4 lg:mx-6 grid gap-3 lg:gap-16 grid-cols-2 lg:grid-cols-3 md:grid-cols-3">
            {sortedProducts.map((product) => (
              <Link
                key={product.productId}
                to={`/Shop/${product.productId}`}
                className="group block overflow-hidden"
              >
                <ProductCard
                  key={product.productId}
                  name={product.productName}
                  oldPrice={product.productOldPrice}
                  price={product.productPrice}
                  image={product.productImage}
                />
              </Link>
            ))}
          </ul>
        ) : (
          <div className="text-center py-10">
            <p className="text-lg text-gray-200">No Products Yet.</p>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default CategoryPage;

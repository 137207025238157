import React from "react";

export default function Price(props) {
  let oldPrice = props.oldPrice;
  let newPrice = props.newPrice;
  return (
    <p className="inline-block text-2xl font-semibold text-gray-200">
      <span className="m-0">LE {newPrice}.00 </span>
      {oldPrice && (
        <span className="text-base font-semibold text-melon line-through ">
          LE {oldPrice}.00
        </span>
      )}
    </p>
  );
}

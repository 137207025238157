import React from "react";

const InputField = ({ label, type, value, onChange, placeholder, id, required }) => (
    <div className="mt-2">
        <label className="sr-only" htmlFor={id}>{label}</label>
        <input
            className="w-full rounded border outline-none border-white p-3 text-sm focus:border-maincolor focus:ring-2 focus:ring-secondarycolor transition-colors duration-200 ease-in-out text-maincolor"
            type={type}
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
    </div>
);

export default InputField;
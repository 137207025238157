/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Thumbnail(props) {
  const { url, handleClick, selected } = props;
  return (
    <div className="w-auto">
      <a
        onClick={handleClick}
        className={`block border-2 rounded-md ${
          selected ? "border-maincolor" : "border-secondarycolor"
        } hover:border-maincolor 
            transition duration-300 ease-in-out transform hover:scale-95 overflow-hidden
             `}
      >
        <img src={url} alt="" className="w-auto h-auto object-cover" />
      </a>
    </div>
  );
}

import React from "react";

function Announcement(props) {
  const { announcement } = props;
  return (
    <div className="bg-white py-3 text-melon font-bold">
      <p className="text-center text-sm">{announcement}</p>
    </div>
  );
}

export default Announcement;

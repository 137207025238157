import React from "react";

function WhoAreWe() {
  return (
    <section className="text-gray-200 body-font mb-10">
      <div className="container mx-auto flex px-2 lg:px-28 py-14 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-4xl mb-4 font-medium text-white">
            FAWDA
          </h1>
          <p className="mb-8 lg:text-xl leading-relaxed">
            FAWDA isn't your parents' closet (well, maybe a cooler version).
            We're all about bringing fresh threads and fierce vibes to your
            everyday life. Founded in 2023, FAWDA is your one-stop shop for
            killer looks that won't quit.
          </p>
          <h2 className="title-font sm:text-2xl text-2xl mb-4 font-semibold text-white">
            Comfort that doesn't cramp your style.
          </h2>
          <p className="mb-8 lg:text-xl leading-relaxed">
            We get it, looking good shouldn't mean feeling like a mummy. That's
            why FAWDA designs clothes that are comfy enough to slay all day and
            night, but still turn heads wherever you go. Think effortless vibes
            that match your unstoppable energy. ⚡️
          </p>
          <h2 className="title-font sm:text-2xl text-2xl mb-4 font-semibold text-white">
            Join the FAWDA fam!
          </h2>
          <p className="mb-8 lg:text-xl leading-relaxed">
            FAWDA isn't just a brand, it's a movement. We're all about
            expressing yourself and feeling confident in your own skin. So rep
            the FAWDA look and show the world what you're made of. Let's rewrite
            the rulebook together.
          </p>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="fawda-home-page-hero-section.jpeg"
          />
        </div>
      </div>
    </section>
  );
}

export default WhoAreWe;

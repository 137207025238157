import sendEmailToAdmin from "../Email/sendEmailToAdmin";
import sendEmailToCustomer from "../Email/sendEmailToCustomer";
import { SubmitOrder } from "./SubmitOrder";
import { checkStockAndNotify } from "./checkStockAndNotify";

export const handleCheckout = async (
  userData,
  items,
  totalAmount,
  shippingPrice,
  alert,
  emptyCart,
  setIsSubmitting
) => {
  setIsSubmitting(true);
  try {
    const isSuccessful = await SubmitOrder(userData, items, totalAmount);
    if (isSuccessful) {
      try {
        checkStockAndNotify(items);
        sendEmailToCustomer(userData, items, totalAmount, shippingPrice);
        sendEmailToAdmin(userData, items, totalAmount, shippingPrice);
        alert.show("Order submitted successfully", { type: "success" });
        setTimeout(() => {
          window.location.href = "/";
          emptyCart();
        }, 2500);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert.show("Failed to submit order", {
        type: "error",
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    // setIsSubmitting(false);
  }
};

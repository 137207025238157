import api from "../api";

export async function GetAllProducts() {
  let products = [];
  try {
    const data = await api.get(`/content/items/product?populate=1`);
    products = data.map((item) => ({
      productId: item._id,
      productName: item.name.replace(/-/g, " "),
      productDescription: item.description,
      productOldPrice: item.oldPrice,
      productPrice: item.price,
      productImages: item.images
        ? item.images
            .filter((image) => image)
            .map((image) => api.getImageUrl(image))
        : [],
      isBestSeller: item.BestSeller,
      isLatestCollection: item.NewItem,
      productVariants: item.variants
        ? item.variants.reduce((unique, variant) => {
            if (
              unique.findIndex(
                (item) => item.variantColorCode === variant.colorCode
              ) === -1
            ) {
              unique.push({
                variantId: variant._id,
                variantColorCode: variant.colorCode,
              });
            }
            return unique;
          }, [])
        : [],
    }));
  } catch (err) {
    console.log(err);
  }

  return products;
}

export async function GetProductById(id) {
  let product = {};
  try {
    const data = await api.get(`/content/item/product/${id}?populate=1`);
    product = {
      productId: data._id,
      productName: data.name,
      productDescription: data.description,
      productPrice: data.price,
      productOldPrice: data.oldPrice,
      productImages: data.images?.map((image) => {
        return api.getImageUrl(image);
      }),
      productThumbnail: data.images?.map((image) => {
        return api.getImageUrl(image, "thumbnail");
      }),
      productVariants: data.variants?.map((variant) => {
        return {
          variantId: variant._id,
          variantColor: variant.color,
          variantColorCode: variant.colorCode,
          variantSize: variant.size,
          variantQuantity: variant.quantity,
        };
      }),
      productSizeChart: data.sizeChart ? api.getImageUrl(data.sizeChart) : null,
      isBestSeller: data.BestSeller,
      isLatestCollection: data.NewItem,
    };
  } catch (err) {
    console.log(err);
  }
  return product;
}

import { AUTH_TOKEN, BEARER } from "../constant";
import api from "./api";

export async function GetCategories() {
    let categories = [];
    try {
        const data = await api.get("/content/items/Categories", {
            headers: {
                Authorization: `${BEARER} ${AUTH_TOKEN}`,
            },
        });
        categories = data.map((item) => {
            return {
                id: item._id,
                title: item.Name,
                path:
                    "/Shop/Categories/" +
                    item.Name.replace(/\s+/g, "-") +
                    "/" +
                    item._id.toString(),
                cName: "nav-text",
            };
        });
    } catch (err) {
        console.log(err);
    }
    return categories;
}

export async function GetCategoriesWithTopProducts() {
    let categories = [];
    try {
        const data = await api.get("/content/items/Categories?populate=1", {
            headers: {
                Authorization: `${BEARER} ${AUTH_TOKEN}`,
            },
        });
        categories = data.map((item) => {
          return {
            id: item._id,
            title: item.Name,
            path:
            "/Shop/Categories/" +
            item.Name.replace(/\s+/g, "-") +
            "/" +
            item._id.toString(),
            products: item.Products.filter((p, idx) => {
                  return idx < 4 
                }).map((product) => {
                  return {
                    productId: product._id,
                    productName: product.name,
                    productOldPrice: product.oldPrice,
                    productPrice: product.price,
                    productImage: api.getImageUrl(product.images[0]),
                  };
                }),
            };
        });
        console.log(categories);
    } catch (err) {
        console.log(err);
    }
    return categories;
}

// get category by name
export async function GetCategoryByName(name) {
    let category = {};
    try {
        const data = await api.get(
            `/content/items/Categories?filter={name:{$eq:"${name}"}}&populate=1`
        );
        if (data.length)
            category = {
                title: data[0].name,
                description: data[0].description,
                products: data[0].products.map((product) => {
                    return {
                        productId: product._id,
                        productName: product.name,
                        productPrice: product.price,
                        productOldPrice: product.oldPrice,
                        productImage: api.getImageUrl(product.images[0]),
                    };
                }),
            };
    } catch (err) {
        console.log(err);
    }
    return category;
}

export async function GetCategoryById(id) {
    let category = {};
    try {
        const data = await api.get(`/content/item/Categories/${id}?populate=1`);
        if (data.Products.length)
            category = {
                title: data.Name,
                // description: data.description,
                products: data.Products.map((product) => {
                    return {
                        productId: product._id,
                        productName: product.name,
                        productOldPrice: product.oldPrice,
                        productPrice: product.price,
                        productImage: api.getImageUrl(product.images[0]),
                    };
                }),
            };
        console.log(category);
    } catch (err) {
        console.log(err);
    }
    return category;
}

import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // replace #root with your app's root element id

export default function SizeChart(props) {
  const { sizeChart } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <details>
      <summary className="text-xl font-bold">Size Chart</summary>
      <img
        src={sizeChart}
        alt="Size Chart"
        className="object-contain w-1/2 h-1/2 mx-auto cursor-zoom-in mt-6 mb-6"
        onClick={openModal}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Size Chart"
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            border: "none",
            display: "flex",
            justifyContent: "center",
            inset: "0",
            overflow: "auto",
          },
        }}
      >
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 p-2 text-4xl text-melon cursor-pointer"
        >
          &times;
        </button>
        <img
          src={sizeChart}
          alt="Size Chart"
          className="object-contain mx-auto lg:h-full lg:w-full"
          onClick={closeModal}
        />
      </Modal>
    </details>
  );
}

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function HeroSection() {
    return (
        <section className='relative h-screen transition-all ease-in duration-700'>
            <motion.img
                src='/fawda-home-page-hero-section.jpeg'
                alt='Background'
                className='absolute h-full w-full object-contain z-0 transition-opacity duration-1000'
                initial={{ opacity: 0, scale: 1.25 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.9, delay: 0.6 }}
            />
            <div className='absolute h-full w-full bg-black bg-opacity-30 z-10'></div>
            <div className='relative h-screen flex items-center justify-center p-6 z-20'>
                <motion.div className='h-12 text-center' initial={{ opacity: 0, translateY: 20}} animate={{ opacity: 1, translateY:0}} transition={{duration: 0.6, delay: 1.7 }}>
                    <h1 className='text-gray-100 font-extrabold text-4xl xs:text-5xl md:text-6xl'>
                        <span className='text-white'>
                            Welcome to the world of
                        </span>{" "}
                        Fawda
                    </h1>
                    <h2 className='text-gray-200 font-extrabold text-3xl xs:text-4xl md:text-5xl leading-tight'>
                        Where Chaos Meets Creativity 🌀
                    </h2>
                    <div className='inline-flex flex-grow justify-end'>
                        <Link
                            to={"/Shop"}
                            className='p-4 my-8 mx-4 bg-maincolor hover:bg-secondarycolor hover:text-maincolor text-xxs md:text-lg uppercase tracking-wide text-white shadow-md transition duration-500'
                        >
                            Shop Now
                        </Link>
                    </div>
                </motion.div>
            </div>
        </section>
    );
}

export default HeroSection;
